.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;
    padding: 10px 15px;
    border-radius: 10px;
}
.picture {
    object-fit: cover;
    width: 100%;
}