.main {
    max-width: 1080px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}
.hending {
    font-size: 40px;
}
.text_box {
    background-color: aliceblue;
    padding: 8px;
    border-radius: 6px;
    width: 880px;
}
.card_box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.card {
    background-color: aliceblue;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
    padding: 20px;
}
.card_heading {
    margin: 0;
    font-size: 30px;
    text-align: center;
}
.link {
    text-decoration: none;
    color: black;
    font-size: 24px;
}
.link:hover {
    opacity: 0.7;
}
@media screen and (max-width: 899px) {
    .text_box {
        width: 420px;
    }
}
@media screen and (max-width: 500px) {
    .text_box {
        width: 320px;
    }
    .hending {
        font-size: 22px;
    }
    .link {
        font-size: 12px;
    }
    .card_heading {
        font-size: 22px;
    }
    .main {
        max-width: 500px;
    }
    .card {
        max-width: 300px;
    }
    }

