.main {
    margin: auto;
    max-width: 1080px;
    padding: 20px;
}
.hending {
    font-size: 30px;
}
.text_box {
    background-color: aliceblue;
    padding: 20px;
    border-radius: 6px;
}
.link_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.link {
    text-decoration: none;
    color: black;
    font-size: 20px;
}
.link:hover {
    opacity: 0.7;
}
.children_box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}
@media screen and (max-width: 500px) {
    .hending {
        font-size: 22px;
    }
    }

